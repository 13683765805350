import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "src/components/Footer/Footer";
import React, { useState } from "react";
import {
  StatusPageDataViewModel,
  StatusPageSiteViewModel,
} from "src/types/auto/types";
import { Container } from "@mui/material";
import AccountSetupPagination from "src/components/AccountSetupPagination/AccountSetupPagination";
import Logo from "src/components/LogoSign";
import StatusPageCreateUpdate from "src/components/StatusPageCreate/StatusPageCreateUpdate";
import { DefaultStatusPage } from "./DefaultStatusPageData";

interface Props {
  smallerView?: boolean;
}

export default function CreateStatusPage(props: Props) {
  // The id must be undefined here for the CreateStatusPageButton.tsx component to create a new site
  const [data, setData] = useState<StatusPageDataViewModel>(
    DefaultStatusPage({ name: "", id: undefined } as StatusPageSiteViewModel)
  );

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Logo height={150} hideNavigation />
        <Typography component="h1" variant="h5" style={{ marginTop: 20 }}>
          Let&apos;s create your status page
        </Typography>
        <Box style={{ width: "100%", textAlign: "left" }}>
          <StatusPageCreateUpdate
            data={data}
            setData={setData}
            smallerView={props.smallerView}
          />
        </Box>
      </Box>
      <AccountSetupPagination
        pageNumber={3}
        isFullAccountSetup={document.URL.includes("signup")}
      />
      <Footer />
    </Container>
  );
}

import * as React from "react";
import { useEffect, useState } from "react";
import {
  OrganisationClient,
  OrganisationViewModel,
  StatusPageDataViewModel,
} from "src/types/auto/types";
import {
  Container,
  FormGroup,
  FormControlLabel,
  Switch,
  styled,
} from "@mui/material";
import StatusPageDisplay from "src/view/pages/StatusPages/StatusPageDisplay";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import BasicStatusPageConfig from "./BasicStatusPageConfig";
import AdvancedStatusPageConfig from "./AdvancedStatusPageConfig";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";

interface Props {
  data: StatusPageDataViewModel;
  setData: React.Dispatch<React.SetStateAction<StatusPageDataViewModel>>;
  update?: boolean;
  smallerView?: boolean;
}

const StatusPageInner = styled(Container)(
  () => `
  flex: 2;
  margin: 0;
  margin-top: 20px;
  padding: 0;
  padding-bottom: 20;
  width: 100%;
  max-height: 75vh;
  overflow: scroll;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.3));
  @media (min-width: 1000px) {
    flex-direction: row;
    margin-left: 5%;
    width: 90%;
  }
`
);

const Wrapper = styled(Container)(
  () => `
  display: flex;
  flex-direction: column-reverse;
  gap: 0;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (min-width: 1000px) {
    flex-direction: row;
    width: 100%;
    gap: 100;
  }
    `
);

const scrollableStatusPageId = "statusPageId";

const StatusPageCreateUpdate = (props: Props): JSX.Element => {
  const [, reRender] = useState(0);
  const [showBasicSettings, setShowBasicSettings] = useState(true);
  const [organisation, setOrganisation] = useState<OrganisationViewModel>();
  const [scrollPosition, setScrollPosition] = useState(0);

  // Get the updated organisation for the user
  useEffect(() => {
    new OrganisationClient(Configuration.ServerRoot, FetchOverride)
      .getOrganisation()
      .then((x) => setOrganisation(x));
  }, []);

  if (document.getElementById(scrollableStatusPageId)) {
    document.getElementById(scrollableStatusPageId).scrollTop = scrollPosition;
  }

  if (props.data === undefined) {
    return (
      <Container style={{ width: "100%", height: "100em" }}>
        <LoadingSpinner loading={true} />
      </Container>
    );
  }

  return (
    <Wrapper>
      <Container
        style={{
          width: "100%",
          margin: 10,
          padding: 0,
          flex: 1,
          minWidth: "320px",
        }}
      >
        <FormGroup
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                value={showBasicSettings}
                onChange={(_, checked) => setShowBasicSettings(!checked)}
              />
            }
            label={showBasicSettings ? "Simple Setup" : "Advanced Setup"}
          />
        </FormGroup>
        {showBasicSettings ? (
          <BasicStatusPageConfig
            data={props.data}
            setData={props.setData}
            reRender={reRender}
            update={props.update}
            organisation={organisation}
          />
        ) : (
          <AdvancedStatusPageConfig
            data={props.data}
            setData={props.setData}
            reRender={reRender}
            update={props.update}
            organisation={organisation}
          />
        )}
      </Container>
      <StatusPageInner
        id={scrollableStatusPageId}
        style={{
          backgroundColor: props.data?.settings?.pageBodyColour,
          padding: 0,
          paddingBottom: 20,
        }}
        onScroll={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setScrollPosition((e.target as any).scrollTop);
        }}
      >
        <StatusPageDisplay data={props.data} />
      </StatusPageInner>
    </Wrapper>
  );
};

export default StatusPageCreateUpdate;

import React, { useContext, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { IsTextValid } from "./EventModalHelper";
import {
  EventStatus,
  Exception,
  SiteStatus,
  TestClient,
  TestViewModel,
} from "src/types/auto/types";
import MultiSelect from "../Multiselect/MultiSelect";
import { FetchOverride } from "src/contexts/Requests";
import { Configuration } from "src/Constants";
import { SetSnackbar } from "src/contexts/SnackbarContext";
import StatusOverrideDropdown from "../StatusOverrideDropdown/StatusOverrideDropdown";
import SiteStatusHeader from "src/view/dashboards/IndividualSiteView/SiteStatusHeader";

interface Props {
  siteId: string;
  focusName?: boolean;
  eventTitle: string;
  setEventTitle: React.Dispatch<React.SetStateAction<string>>;
  eventDescription: string;
  setEventDescription: React.Dispatch<React.SetStateAction<string>>;
  eventType: EventStatus;
  setEventType: React.Dispatch<React.SetStateAction<EventStatus>>;
  testsImpacted: string[];
  setTestsImpacted: React.Dispatch<React.SetStateAction<string[]>>;
  disableOverriddenOptions: boolean;
  testsOverridden: TestViewModel[];
  setTestsOverridden: React.Dispatch<React.SetStateAction<TestViewModel[]>>;
  eventTitleError: boolean;
  setEventTitleError: React.Dispatch<React.SetStateAction<boolean>>;
  eventDescriptionError: boolean;
  setEventDescriptionError: React.Dispatch<React.SetStateAction<boolean>>;
  testsImpactedError: boolean;
  setTestsImpactedError: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventModalForm = (props: Props): JSX.Element => {
  const [tests, setTests] = useState<TestViewModel[]>([]);
  const [, reRender] = useState(false);
  const setSnackbar = useContext(SetSnackbar);

  useEffect(() => {
    new TestClient(Configuration.ServerRoot, FetchOverride)
      .getTestsForSite(props.siteId)
      .then(setTests)
      .catch(() =>
        setSnackbar({
          message:
            "Could not retrieve tests. Please close this dialog and try again",
          status: "error",
        })
      );
  }, []);

  return (
    <>
      <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
        <InputLabel id="eventTypeLabel">Status</InputLabel>
        <Select
          labelId="eventTypeLabel"
          id="eventType"
          value={props.eventType}
          input={<OutlinedInput id="selectChip" label="Status" />}
          onChange={(e) => props.setEventType(e.target.value as EventStatus)}
        >
          <MenuItem
            key={EventStatus.Investigating}
            value={EventStatus.Investigating}
          >
            Investigating
          </MenuItem>
          <MenuItem key={EventStatus.Fixing} value={EventStatus.Fixing}>
            Fixing
          </MenuItem>
          <MenuItem key={EventStatus.Update} value={EventStatus.Update}>
            Update
          </MenuItem>
          <MenuItem key={EventStatus.Resolved} value={EventStatus.Resolved}>
            Resolved
          </MenuItem>
          <MenuItem
            key={EventStatus.Maintenance}
            value={EventStatus.Maintenance}
          >
            Maintenance
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        id="name"
        label="Title"
        fullWidth
        variant="standard"
        placeholder=""
        value={props.eventTitle}
        onChange={(e) => props.setEventTitle(e.target.value)}
        onBlur={() => props.setEventTitleError(!IsTextValid(props.eventTitle))}
        error={props.eventTitleError}
        helperText={props.eventTitleError && "Please enter a valid title"}
      />
      <TextField
        margin="dense"
        id="name"
        label="Description"
        fullWidth
        multiline
        rows={4}
        variant="standard"
        placeholder=""
        value={props.eventDescription}
        onChange={(e) => props.setEventDescription(e.target.value)}
        onBlur={() =>
          props.setEventDescriptionError(!IsTextValid(props.eventTitle))
        }
        error={props.eventDescriptionError}
        helperText={
          props.eventDescriptionError && "Please enter a valid description"
        }
      />
      <Typography style={{ marginTop: "10px" }} variant="h5">
        Select Affected Tests
      </Typography>
      <FormGroup>
        {tests?.map((x) => (
          <Box
            key={x.id}
            sx={{
              display: "flex",
              justifyContent: { md: "space-between", xs: "flex-start" },
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.testsImpacted.includes(x.id)}
                  onChange={(_, checked) => {
                    props.setTestsImpacted((y) => {
                      if (checked) {
                        y.push(x.id);
                      } else {
                        y = y.filter((z) => z !== x.id);
                        props.setTestsOverridden((z) =>
                          z.filter((a) => a.id !== x.id)
                        );
                      }

                      reRender((n) => !n);
                      return y;
                    });
                  }}
                />
              }
              label={x.name}
            />
            {props.testsImpacted?.includes(x.id) &&
              !props.disableOverriddenOptions && (
                <Box sx={{ width: { sx: "100%", md: 300 } }}>
                  <StatusOverrideDropdown
                    overrideValue={
                      props.testsOverridden.find((y) => y.id === x.id)
                        ?.overriddenStatus
                    }
                    setOverrideValue={(val) =>
                      props.setTestsOverridden((y) => {
                        const index = y.findIndex((z) => z.id === x.id);
                        if (index !== -1) {
                          y[index].overriddenStatus = val as SiteStatus;
                        } else {
                          y.push({
                            ...x,
                            overriddenStatus: val as SiteStatus,
                          } as TestViewModel);
                        }
                        reRender((x) => !x);
                        return y;
                      })
                    }
                    pauseTest={false}
                    setPauseTest={() => {
                      // Throw an exception as this should not be possible
                      throw new Exception();
                    }}
                    isStatusOverride={false}
                    width="100%"
                  />
                </Box>
              )}
          </Box>
        ))}
      </FormGroup>
    </>
  );
};

export default EventModalForm;
